import React from "react";
import logo from "./logo.svg";
import { Helmet } from "react-helmet";

import Hero from "./components/hero";
import Brands from "./components/brands";
import Apps from "./components/apps";
import Projects from "./components/projects";
import Testimonials from "./components/testimonials";
import Contact from "./components/contact";

function App() {
  return (
    <>
      <Helmet>
        <title>Filip Kania</title>
        <meta name="description" content="Filip Kania - Full-stack Developer" />
        <meta name="author" content="Filip" />
        <meta property="og:title" content="Filip Kania" />
        <meta
          property="og:description"
          content="Filip Kania - Full-stack Developer"
        />
        <meta
          property="og:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/managly-pl/ifil-opengraph.png"
        />
        <meta
          property="og:url"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/managly-pl/ifil-opengraph.png"
        />
        <meta name="twitter:title" content="Filip Kania" />
        <meta
          name="twitter:description"
          content="Filip Kania - Full-stack Developer"
        />
        <meta
          name="twitter:image"
          content="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/managly-pl/ifil-opengraph.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <main className="relative overflow-hidden">
        <div className="relative z-20 bg-zinc-950">
          <Hero />
          <Brands />
          <Apps />
          <Projects />
          <Testimonials />
          <Contact />
        </div>
      </main>
    </>
  );
}

export default App;
