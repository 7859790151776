import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Testimonials() {
  const bgColor = {
    backgroundColor: "rgb(34, 34, 34)",
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <section
      id="testimonials"
      className="flex flex-col gap-4 px-12 lg:px-36 items-center justify-center bg-zinc-950"
    >
      <span className="border border-blue-600 px-12 py-1 text-blue-600 rounded-xl">
        testionials
      </span>
      <h2 className="text-6xl text-white text-center mb-12">
        What my Client say
      </h2>
      <div className="w-full flex flex-col lg:flex-row gap-4">
        <div className="w-full">
          <div className="rounded-xl lightBorder gap-6 backdrop-blur-xl py-6 px-12 flex flex-row items-center justify-start">
            <img
              className="w-20"
              src="https://ifil-webdesign.pl/_next/image?url=%2FmainPage%2FtrustedUs%2Fgoogle.png&w=96&q=75"
              alt=""
            />
            <div className="flex flex-col gap-1">
              <p className="font-semibold text-2xl text-white">5.0 / 5.0</p>
              <p className="text-lg text-gray-400">
                Average of over 40 opinions
              </p>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="rounded-xl lightBorder gap-6 backdrop-blur-xl py-6 px-12 flex flex-row items-center justify-start">
            <img
              className="w-20"
              src="https://ifil-webdesign.pl/_next/image?url=%2FmainPage%2FtrustedUs%2Ffacebook.webp&w=96&q=75"
              alt=""
            />
            <div className="flex flex-col gap-1">
              <p className="font-semibold text-2xl text-white">5.0 / 5.0</p>
              <p className="text-lg text-gray-400">
                Average of over 5 opinions
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 w-full gap-4 relative">
        <div className="flex flex-col gap-4">
          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Bartosz Fijałkowski</p>
            <p className="text-gray-400">
              Z Filipem, jako klient (zleceniodawca), nawiązałem współpracę
              poprzez useme. Zrealizował brief zgodnie z oczekiwaniami.
              Wyróżniał się jakością komunikacji :) Polecam.
            </p>
          </div>

          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Katarzyna Szebesta</p>
            <p className="text-gray-400">
              Jestem bardzo zadowolona ze współpracy i efektu końcowego mojej
              nowej strony internetowej. Wszystko przebiegło zdalnie, bez
              żadnych opóźnień, profesjonalnie i konkretnie.{" "}
            </p>
          </div>

          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Dagmara Obsłuska</p>
            <p className="text-gray-400">
              Kulturalna współpraca na najwyższym poziomie, sprawnie i z
              cierpliwością dla moich licznych pytań. Szczerze polecam!
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Julia Przybyła</p>
            <p className="text-gray-400">
              Bardzo serdecznie polecam współpracę z Filipem. Mój pomysł na
              stronę został poprawiony i ulepszony dzięki wiedzy Filipa, co
              sprawiło, że strona wyszła super.
            </p>
          </div>

          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Paulina Patro</p>
            <p className="text-gray-400">
              Potrzebowałam aby ktoś pilnie i z polotem zaprojektował mi stronę.
              Pan Filip wykazał się wiedzą której nie miałam, podpowiadał,
              sugerował. Szybko rozumiał czego potrzebowałam i dostosowywał to
              do najnowszych trendów.{" "}
            </p>
          </div>

          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Monika Płaza</p>
            <p className="text-gray-400">
              Bardzo polecam współpracę z Panem Filipem. Strona wykonana
              profesjonalnie, bardzo dobra komunikacja
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Edyta Mycek</p>
            <p className="text-gray-400">
              Bardzo dobra współpraca. Potrzebowaliśmy strony gotowej na szybko
              i udało się. Bardzo duża elsatyczność. Świetny kontakt. Szybko
              nanoszone wszelkie poprawki jakie chcieliśmy. Szczerze polecam.
            </p>
          </div>

          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Aleksandra Dobrzańska</p>
            <p className="text-gray-400">
              Bardzo polecam współpracę z Panem Filipem - pełen profesjonalizm,
              szybkość działania, fajne pomysły i niespotykana cierpliwość do
              klienta.
            </p>
          </div>

          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Anna Jarecka</p>
            <p className="text-gray-400">
              Pełen profesjonalizm, bardzo dobra komunikacja z klientem, szybka
              i przede wszystkim mieszcząca się w obiecanym terminie realizacja
              zlecenia.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Grzegorz Żurawski</p>
            <p className="text-gray-400">
              Pełen profesjonalizmu w budowaniu strony sklepu internetowego.
              Wzorowy kontakt,bardzo szybka odpowiedź na pytania. Wiele trafnych
              sugestii dotyczących różnych elementów strony. Ceny bardzo
              konkurencyjne, a jakość usług na najwyższym poziomie. Będę polecał
              każdemu. Pozdrawiam
            </p>
          </div>

          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Urszula Majdanek</p>
            <p className="text-gray-400">
              Bardzo polecam współpracę z Panem Filipem. Pełen profesjonalizm,
              szybka realizacja.{" "}
            </p>
          </div>

          <div className="flex rounded-xl flex-col gap-4 p-6" style={bgColor}>
            <p className="text-white font-semibold">Wioleta Jasińska</p>
            <p className="text-gray-400">
              Strona internetowa wykonana według ustaleń. Jestem bardzo
              zadowolona z efektu wizualnego
            </p>
          </div>
        </div>
        <div className="w-full absolute bottom-0 h-96 testimonialGradient"></div>
      </div>
    </section>
  );
}

export default Testimonials;
