import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Projects() {
  const [ref, inView] = useInView({
    triggerOnce: false,
  });
  return (
    <section className="flex flex-col bg-zinc-950">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="project w-full relative flex flex-col items-center justify-center">
          <div className="projectOverlay bg-black absolute w-full h-full z-20 transition duration-300 ease-in-out"></div>
          <a
            href="https://red-sky.com/blog"
            className="projectTitle text-white text-4xl absolute z-30"
          >
            RedSky
          </a>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/portfolio-mockup-1.webp"
            className="z-10"
            alt=""
          />
        </div>
        <div className="project w-full relative flex flex-col items-center justify-center">
          <div className="projectOverlay bg-black absolute w-full h-full z-20 transition duration-300 ease-in-out"></div>
          <a
            href="https://artoni.pl"
            className="projectTitle text-white text-4xl absolute z-30"
          >
            Artoni Foundation
          </a>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/portfolio-mockup-2.webp"
            className="z-10"
            alt=""
          />
        </div>
        <div className="project w-full relative flex flex-col items-center justify-center">
          <div className="projectOverlay bg-black absolute w-full h-full z-20 transition duration-300 ease-in-out"></div>
          <a
            href="https://kasiatlumaczy.pl"
            className="projectTitle text-white text-4xl absolute z-30"
          >
            Kasia Tłumaczy
          </a>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/portfolio-mockup-3.webp"
            className="z-10"
            alt=""
          />
        </div>
        <div className="project w-full relative flex flex-col items-center justify-center">
          <div className="projectOverlay bg-black absolute w-full h-full z-20 transition duration-300 ease-in-out"></div>
          <a
            href="https://wpolszczi.pl"
            className="projectTitle text-white text-4xl absolute z-30"
          >
            wPolszczi
          </a>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/portfolio-mockup-4.webp"
            className="z-10"
            alt=""
          />
        </div>
        <div className="project w-full relative flex flex-col items-center justify-center">
          <div className="projectOverlay bg-black absolute w-full h-full z-20 transition duration-300 ease-in-out"></div>
          <a
            href="https://mtawedding.pl"
            className="projectTitle text-white text-4xl absolute z-30"
          >
            MTA Wedding
          </a>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/portfolio-mockup-5.webp"
            className="z-10"
            alt=""
          />
        </div>
        <div className="project w-full relative flex flex-col items-center justify-center">
          <div className="projectOverlay bg-black absolute w-full h-full z-20 transition duration-300 ease-in-out"></div>
          <a
            href="https://e-pojazd.com"
            className="projectTitle text-white text-4xl absolute z-30"
          >
            ePojazd
          </a>
          <img
            src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/portfolio-mockup-6.webp"
            className="z-10"
            alt=""
          />
        </div>
      </div>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
        transition={{ duration: 0.3 }}
        style={{
          width: "100%",
        }}
      >
        {inView && (
          <div className="px-12 lg:px-24 py-12 grid grid-cols-3 lg:grid-cols-8">
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-1.png"
                alt="Project 1"
                className="w-2/3"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-2.png"
                alt="Project 2"
                className="w-2/3"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-3.png"
                alt="Project 3"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-4.png"
                alt="Project 4"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-5.png"
                alt="Project 5"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-6.png"
                alt="Project 6"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-7.png"
                alt="Project 7"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-8.png"
                alt="Project 8"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-9.png"
                alt="Project 9"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-10.png"
                alt="Project 10"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-11.png"
                alt="Project 11"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-12.png"
                alt="Project 12"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-13.png"
                alt="Project 13"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-14.png"
                alt="Project 14"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-15.png"
                alt="Project 15"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-16.png"
                alt="Project 16"
                className="w-2/3"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-17.png"
                alt="Project 17"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-18.png"
                alt="Project 18"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-19.png"
                alt="Project 19"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-20.png"
                alt="Project 20"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-21.png"
                alt="Project 21"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-22.png"
                alt="Project 22"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-23.png"
                alt="Project 23"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-6">
              <img
                src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/ifil-pl/Offer/Customers/logo-24.png"
                alt="Project 24"
                className="w-full"
              />
            </div>
          </div>
        )}
      </motion.div>
    </section>
  );
}

export default Projects;
