import React, { useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Apps() {
  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    dots: true,
  };

  const [ref, inView] = useInView({
    triggerOnce: false,
    rootMargin: "-50px 0px",
  });

  return (
    <section id="apps" className="flex flex-col relative backdrop-blur-xl">
      <div className="z-30 relative flex flex-col items-center justify-center lg:mt-24">
        <video
          loop={true}
          muted={true}
          autoPlay={true}
          className="hidden lg:flex w-full"
          src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/app-video.mp4"
        ></video>
        <div className="w-full h-full backdrop-blur-lg z-20 hidden lg:flex absolute appsGradient"></div>
        <div className="lg:absolute w-full p-12 lg:p-24 z-30">
          <h2 className="text-white text-6xl lg:text-8xl pl-6 mb-24">
            My personal apps
          </h2>
          <div className="slider flex flex-row items-center justify-between">
            <Slider {...settings}>
              <div className="containter">
                <div className="w-full flex flex-col gap-4">
                  <p className="text-4xl text-white">ContractorFlow</p>
                  <p className="text-lg text-white">
                    My proprietary web application is crafted for sales
                    representatives. I designed the system with a special
                    emphasis on new and small businesses in the sales sector.
                    With our CRM, I can efficiently manage all clients from one
                    centralized platform. ContractorFlow also includes
                    integration with the BIR1.1 system, allowing me to retrieve
                    company information based on the NIP (Tax Identification
                    Number) from the GUS (Central Statistical Office) database.
                  </p>
                  <a href="">
                    <button className="text-white border border-white px-6 py-1 rounded-xl">
                      check this out
                    </button>
                  </a>
                  <div className="flex flex-row items-center justify-start gap-12">
                    <img
                      className="h-12 lg:h-24"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-python.webp"
                      alt=""
                    />
                    <img
                      className="h-16 lg:h-36"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-django.webp"
                      alt=""
                    />
                    <img
                      className="h-16 lg:h-36"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-tailwind.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full">
                  <img
                    src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/contractor-flow.webp"
                    alt=""
                  />
                </div>
              </div>
              <div className="containter">
                <div className="w-full flex flex-col gap-4">
                  <p className="text-4xl text-white">Porównaj Solary</p>
                  <p className="text-lg text-white">
                    The aim of the Porównaj Solary app is to bring together a
                    database of photovoltaic panel distributors. The project has
                    a separate front-end layer, created using NextJS, and a
                    back-end, which is based on Python Django. These layers are
                    connected via the Django REST Framework. The application
                    also has a blog. In the future, a calculator based on an AI
                    model will be developed to select the best provider based on
                    the intelligence collected.
                  </p>
                  <a href="">
                    <button className="text-white border border-white px-6 py-1 rounded-xl">
                      check this out
                    </button>
                  </a>
                  <div className="flex flex-row items-center justify-start gap-12">
                    <img
                      className="h-12 lg:h-24"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-nextjs.png"
                      alt=""
                    />
                    <img
                      className="h-16 lg:h-36"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-django.webp"
                      alt=""
                    />
                    <img
                      className="h-16 lg:h-36"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-restapi.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full">
                  <img
                    src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/porownaj-solary.webp"
                    alt=""
                  />
                </div>
              </div>
              <div className="containter">
                <div className="w-full flex flex-col gap-4">
                  <p className="text-4xl text-white">Managly</p>
                  <p className="text-lg text-white">
                    Managly uses the Django framework. The product allows
                    project statuses as well as invoices to be stored in a
                    database. The application is aimed at freelancers and
                    creative agencies who want to improve their online image and
                    provide their clients with a tool to oversee project work.
                  </p>
                  <a href="">
                    <button className="text-white border border-white px-6 py-1 rounded-xl">
                      check this out
                    </button>
                  </a>
                  <div className="flex flex-row items-center justify-start gap-12">
                    <img
                      className="h-12 lg:h-24"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-python.webp"
                      alt=""
                    />
                    <img
                      className="h-16 lg:h-36"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-django.webp"
                      alt=""
                    />
                    <img
                      className="h-16 lg:h-36"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-tailwind.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full">
                  <img
                    src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/managly.webp"
                    alt=""
                  />
                </div>
              </div>
              <div className="containter">
                <div className="w-full flex flex-col gap-4">
                  <p className="text-4xl text-white">Kasia Tłumaczy</p>
                  <p className="text-lg text-white">
                    Project based on the Python programming language using the
                    Django framework. The aim of the application is automatic
                    translation of PDF files. After processing and translating,
                    the program returns a new PDF file in English. The
                    application utilizes OpenAI, allowing for translation by a
                    more accurate model than standard programs such as Google
                    Translate.
                  </p>
                  <a href="">
                    <button className="text-white border border-white px-6 py-1 rounded-xl">
                      check this out
                    </button>
                  </a>
                  <div className="flex flex-row items-center justify-start gap-12">
                    <img
                      className="h-12 lg:h-24"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-python.webp"
                      alt=""
                    />
                    <img
                      className="h-16 lg:h-36"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-django.webp"
                      alt=""
                    />
                    <img
                      className="h-16 lg:h-36"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-tailwind.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full">
                  <img
                    src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/kasiatlumaczy.webp"
                    alt=""
                  />
                </div>
              </div>
              <div className="containter">
                <div className="w-full flex flex-col gap-4">
                  <p className="text-4xl text-white">iFil Group</p>
                  <p className="text-lg text-white">
                    The application was created to better organise work with
                    clients. It has four levels of access - client, employee,
                    partner and administrator. The product makes it possible to
                    manage project statuses, send invoices and contracts to the
                    client. An additional functionality is the partner system.
                    The application also has a support system based on
                    submitting tickets from the client level and managing them
                    from the employee level.
                  </p>
                  <a href="">
                    <button className="text-white border border-white px-6 py-1 rounded-xl">
                      check this out
                    </button>
                  </a>
                  <div className="flex flex-row items-center justify-start gap-12">
                    <img
                      className="h-12 lg:h-24"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-python.webp"
                      alt=""
                    />
                    <img
                      className="h-16 lg:h-36"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-django.webp"
                      alt=""
                    />
                    <img
                      className="h-16 lg:h-36"
                      src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/stack/stack-tailwind.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full">
                  <img
                    src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/ifil.webp"
                    alt=""
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <motion.div
        ref={ref}
        className={`box ${inView ? "visible" : ""}`}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={inView ? { opacity: 1, scale: 1 } : {}}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <h2 className="text-6xl lg:text-9xl text-white text-left leading-tight pt-12 lg:pt-24 pb-12 lg:pb-36 px-12 lg:px-24">
          Over 100
          <br />
          commercial
          <br />
          projects
        </h2>
      </motion.div>
    </section>
  );
}

export default Apps;
